import Layout from '@/page/index/'

export default [
  // {
  //   path: '/',
  //   component: Layout,
  //   redirect: '/index',
  //   children: [{
  //     path: 'index',
  //     name: '首页',
  //     component: () => import('@/page/login/selectInstitution')
  //   }]
  // },
  {
    path: '/',
    component: Layout,
    redirect: '/selectInstitution',
    children: [{
      path: 'index',
      name: '首页',
      component: () => import('@/views/index')
    }]
  },
  {
    path: '/index',
    component: Layout,
    redirect: '/index',
    children: [{
      path: 'index',
      name: '首页',
      component: () => import('@/views/index')
    }]
  }
]
