<template>
  <div class="vue-sidebar">
    <logo></logo>
    <el-scrollbar style="height:100%">
      <div v-if="validatenull(menu)" class="vue-sidebar--tip">没有发现菜单</div>
      <el-menu unique-opened :default-active="nowTagValue" mode="vertical" :show-timeout="200">
        <sidebar-item :menu="menu" first :props="menuDefault.props"/>
      </el-menu>
    </el-scrollbar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import logo from '../logo'
import sidebarItem from './sidebarItem'

export default {
  name: 'Sidebar',
  components: { sidebarItem, logo },
  created () {
    this.$store.dispatch('GetMenu').then(data => {
      if (data.length === 0) return
      this.$router.$vueRouter.formatRoutes(data, true)
    })
  },
  computed: {
    ...mapGetters(['menu', 'menuDefault']),
    nowTagValue: function () {
      return this.$router.$vueRouter.getValue(this.$route)
    }
  }
}
</script>

