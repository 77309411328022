import { removeToken, setToken } from '@/util/auth'
import { getStore, setStore } from '@/util/storage'
import { getUserInfo, loginByUsername } from '@/api/login'
import { tree } from '@/api/sys/menu'
import { isURL, validateNull } from '@/util/validate'
import { deepClone } from '@/util/index'
import { menuDefault } from '@/config'

function addPath (ele, first) {
  const propsConfig = menuDefault.props
  const propsDefault = {
    label: propsConfig.label || 'name',
    path: propsConfig.path || 'path',
    icon: propsConfig.icon || 'icon',
    children: propsConfig.children || 'children'
  }
  const icon = ele[propsDefault.icon]
  ele[propsDefault.icon] = validateNull(icon) ? menuDefault.iconDefault : icon
  const isChild = ele[propsDefault.children] && ele[propsDefault.children].length !== 0
  if (!isChild) ele[propsDefault.children] = []
  if (!isChild && first && !isURL(ele[propsDefault.path])) {
    ele[propsDefault.path] = ele[propsDefault.path] + '/index'
  } else {
    ele[propsDefault.children].forEach(child => {
      addPath(child)
    })
  }
}

const user = {
  state: {
    userInfo: getStore('userInfo') || [],
    permission: getStore('permission') || {},
    roles: [],
    menu: getStore('menu') || [],
    menuAll: [],
    token: getStore('token') || ''
  },
  actions: {
    // 根据用户名登录
    LoginByUsername ({ commit }, userInfo) {
      return new Promise((resolve) => {
        loginByUsername(userInfo.username, userInfo.password).then(res => {
          const data = res.data
          commit('SET_TOKEN', data.token)
          commit('SET_USER_INFO', data)
          resolve()
        })
      })
    },
    GetButtons ({ commit }) {
      return new Promise((resolve) => {
        tree(2).then(res => {
          const data = res.data
          commit('SET_PERMISSION', data)
          resolve()
        })
      })
    },
    // 根据手机号登录
    LoginByPhone ({ commit }, userInfo) {
      return new Promise((resolve) => {
        loginByUsername(userInfo.phone, userInfo.code).then(res => {
          const data = res.data
          commit('SET_TOKEN', data)
          resolve()
        })
      })
    },

    GetUserInfo ({ commit }) {
      return new Promise((resolve, reject) => {
        getUserInfo().then((res) => {
          const data = res.data
          commit('SET_ROLES', data.roles)
          resolve(data)
        }).catch(err => {
          reject(err)
        })
      })
    },

    // 注销session
    LogOut ({ commit }) {
      return new Promise(resolve => {
        commit('SET_TOKEN', '')
        commit('SET_MENU', [])
        commit('SET_ROLES', [])
        removeToken()
        resolve()
      })
    },

    // 获取系统菜单
    GetMenu ({ commit, dispatch }) {
      return new Promise(resolve => {
        tree(1).then((res) => {
          const data = res.data
          const menu = deepClone(data)
          menu.forEach(ele => {
            addPath(ele, true)
          })
          commit('SET_MENU', menu)
          dispatch('GetButtons')
          resolve(menu)
        })
      })
    }
  },
  mutations: {
    SET_TOKEN: (state, token) => {
      setToken(token)
      state.token = token
      setStore('token', state.token)
    },
    SET_USER_INFO: (state, userInfo) => {
      state.userInfo = userInfo
      setStore('userInfo', state.userInfo)
    },
    SET_MENU: (state, menu) => {
      state.menu = menu
      setStore('menu', state.menu)
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles
    },
    SET_PERMISSION: (state, permission) => {
      const result = []

      function getCode (list) {
        list.forEach(ele => {
          if (typeof (ele) === 'object') {
            const children = ele.children
            const code = ele.code
            if (children) {
              getCode(children)
            } else {
              result.push(code)
            }
          }
        })
      }

      getCode(permission)
      state.permission = {}
      result.forEach(ele => {
        state.permission[ele] = true
      })
      setStore('permission', state.permission)
    }
  }

}
export default user
