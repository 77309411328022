<template>
  <div class="vue-top">
    <div class="top-bar__left">
    </div>
    <div class="top-bar__right">
      <el-button class="name" type="text" @click="goInfo"></el-button>
      <span>{{ userInfo.userName }}</span>
      <span><i class="icon-tuichu" @click="logout"></i></span>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'

export default {
  components: {},
  name: 'Top',
  data () {
    return {}
  },
  filters: {},
  created () {
  },
  mounted () {
  },
  computed: {
    ...mapState({}),
    ...mapGetters([
      'userInfo'
    ])
  },
  methods: {
    goInfo () {
      this.$router.push('/')
    },
    logout () {
      this.$confirm('退出系统, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$store.dispatch('LogOut').then(() => {
          window.localStorage.clear()
          this.$router.push({ path: '/login' })
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.top-bar__right {
  span {
    font-size: 15px;
    margin-right: 5px;
  }

}
</style>
