import { getStore, removeStore, setStore } from '@/util/storage'

const TokenKey = 'token'

export function getToken () {
  return getStore(TokenKey)
}

export function setToken (token) {
  return setStore(TokenKey, token)
}

export function removeToken () {
  return removeStore(TokenKey)
}
