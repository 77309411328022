const title = '大白健康星球'

// 配置菜单的属性
const menuDefault = {
  iconDefault: 'iconfont icon-caidan',
  props: {
    label: 'name',
    path: 'path',
    icon: 'icon',
    children: 'children'
  }
}

const config = {
  development: {
    // baseUrl: 'http://localhost:6126/admin'
    // baseUrl: 'https://dbadmin.kequbang.com/admin'
    baseUrl: 'https://dbadmin.dbzdjk.com/admin'
    // baseUrl: 'http://47.101.202.34:6126/admin'
    // baseUrl: 'http://47.101.202.34:6126/admin'
  },
  production: {
    baseUrl: 'https://dbadmin.dbzdjk.com/admin'
  },
  test: {
    baseUrl: 'http://47.101.202.34:6127/admin'
  }
}

const env = process.env

const baseUrl = config[env.NODE_ENV].baseUrl

export {
  title,
  baseUrl,
  menuDefault
}
