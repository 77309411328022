<template>
  <div class="vue-content">
    <div class="vue-header">
      <!-- 顶部导航栏 -->
      <top/>
    </div>
    <div class="vue-layout">
      <div class="vue-left">
        <!-- 左侧导航栏 -->
        <sidebar/>
      </div>
      <div class="vue-main">
        <div style="display:flex;height: 100%;">
          <!-- 主体视图层 -->
          <el-scrollbar style="height:100%;flex:1">
            <keep-alive>
              <router-view class="vue-view" v-if="$route.meta.keepAlive"/>
            </keep-alive>
            <router-view class="vue-view" v-if="!$route.meta.keepAlive"/>
          </el-scrollbar>
        </div>
      </div>
    </div>
    <div class="vue-shade"></div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import top from './top/'
import sidebar from './sidebar/'

export default {
  components: {
    top,
    sidebar
  },
  name: 'Index',
  data () {
    return {}
  },

  mounted () {
  },
  computed: mapGetters([]),
  props: [],
  methods: {}
}
</script>
