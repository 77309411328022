import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from './util/http'
import store from './store'
import './styles/index.less'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import Avue from '@smallwei/avue'
import echarts from 'echarts'
import '@smallwei/avue/lib/index.css'
import AvueUeditor from 'avue-plugin-ueditor'
import './styles/common.scss'
import basicContainer from './components/basic-container/main'
import user from './page/login/js/user.js'

window.axios = axios

Vue.use(ElementUI, { size: 'small' })
Vue.use(AvueUeditor)
Vue.prototype.$echarts = echarts
Vue.prototype.$myUser = user

// 注册全局容器
Vue.component('basicContainer', basicContainer)
Vue.config.productionTip = false

Vue.use(Avue, {
  ali: {
    region: 'oss-cn-shanghai',
    endpoint: 'oss-cn-shanghai.aliyuncs.com',
    accessKeyId: 'LTAI4GGYDGZjaZQyshkjPBJH',
    accessKeySecret: '5O9VCIRDa6AqHtVj2NDiMUO9Uv2GDe',
    bucket: 'health-planet'
  }
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
